import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import QuizComponent from "../../components/makeup-quiz/QuizComponent";

const MakeUpQuiz = () => {
  return (
    <Layout>
      <Seo title="Make Up Quiz" />
      <QuizComponent />
    </Layout>
  );
};

export default MakeUpQuiz;
