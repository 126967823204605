import React, { useState, useEffect } from "react";
import qore from "../../context/qoreContext";
import {
  Box,
  Progress,
  Center,
  Text,
  Button,
  useBoolean,
  Grid,
  GridItem,
  Image,
  Container,
  Spinner,
  SimpleGrid,
  Modal,
  useMediaQuery
} from "@chakra-ui/react";
import styled from "styled-components";
import DOMPurify from "dompurify";
import QuizBg from "../../images/quiz-bg.png";
import ProductModal from "../product/ProductModal";
import QuizStart from "../../images/quiz-makeup.png";

const createMarkup = html => {
  return {
    __html: DOMPurify.sanitize(html)
  };
};

const Background = styled.div`
  background-image: url(${QuizBg});
  background-size: 100% 100%;
  min-height: 80vh;
`;

const ResultArticle = styled(Box)`
  text-align: justify;
  line-height: 24px;
  font-size: 20px;

  p {
    margin-bottom: 16px;
  }
`;

const QuizComponent = () => {
  const [started, setStarted] = useBoolean(false);
  const [finished, setFinished] = useBoolean(false);
  const [modalShow, setModalShow] = useBoolean(false);
  const [indeks, setIndeks] = useState(0);
  const [noVariant, setNoVariant] = useState("00");
  const [quizDisplay, setQuizDisplay] = useState(null);
  const [quizResult, setQuizResult] = useState(null);
  const [resultList, setResultList] = useState([]);
  const [progress, setProgress] = useState(0);
  const [popupData, setPopupData] = useState({});
  const [isMobile] = useMediaQuery("(max-width: 820px)");
  const { data: dataQuiz, status: statusQuiz } = qore.view("activeQuiz").useListRow({
    offset: indeks,
    limit: 1
  });
  const { data: dataResult, status: statusResult } = qore.view("activeProducts").useListRow({
    variant: noVariant,
    parentName: "Matte Kiss Lip Cream"
  });
  const { data: listProduct } = qore.view("activeProducts").useListRow({});

  useEffect(() => {
    if (statusQuiz === "success") {
      setQuizDisplay(dataQuiz[0]);
    }
  }, [dataQuiz, statusQuiz]);

  useEffect(() => {
    if (statusResult === "success") {
      setQuizResult(dataResult[0]);
    }
  }, [dataResult, statusResult]);

  useEffect(() => {
    if (listProduct) {
      const tempResult = listProduct.map(v => ({ ...v, score: 0 }));
      setResultList(tempResult);
    }
  }, [listProduct]);

  useEffect(() => {
    if (finished) {
      const handleSort = () => {
        let sorted = resultList.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));
        setNoVariant(() => sorted[0].no);
      };

      handleSort();
    }
  }, [finished, resultList]);

  const handleStart = () => {
    setStarted.on();
    setFinished.off();
    setProgress((0 / 7) * 100);
  };

  const handleNext = () => {
    if (indeks < 6) {
      let input = indeks + 1;
      setIndeks(input);
      handleProgress();
    } else {
      handleProgress();
      setStarted.off();
      setFinished.on();
    }
  };

  const handleScore = input => {
    let answerList = input.answer;

    answerList.forEach(answer => {
      const tempArr = [...resultList];
      const target = tempArr.find(o => o.no === answer.noLipstick);
      if (target) {
        target.score += answer.scoreLipstick;
        setResultList(tempArr);
      }
    });
  };

  const handleClick = answer => {
    handleNext();
    handleScore(answer);
  };

  const handleProgress = () => {
    let progressValue = ((indeks + 1) / 7) * 100;
    setProgress(progressValue);
  };

  const handleTryAgain = () => {
    setStarted.on();
    setProgress((0 / 7) * 100);
    setIndeks(0);
    setFinished.off();
    setNoVariant("00");
    const cobaArr = listProduct.map(v => ({ ...v, score: 0 }));
    setResultList(cobaArr);
  };

  const handleClose = () => {
    setPopupData({});
    setModalShow.off();
  };

  const handleOpen = data => {
    const obj = {
      name: data.name,
      imgSwatch: data.imgProduct,
      tokopedia: data.tokopedia,
      shopee: data.shopee,
      lazada: data.lazada,
      blibli: data.blibli,
      tiktok: data.tiktok,
      toko: data.toko,
      whatsapp: data.whatsapp,
      isSachet: data.isSachet,
      type: data.type,
      no: data.no
    };
    setPopupData(obj);
    setModalShow.on();
  };

  const StartComponent = () => {
    return (
      <Box textAlign={"center"}>
        <Box mt={{ base: "32px", md: "56px" }}>
          <Text fontSize={{ md: "24px", lg: "28px" }} fontWeight="semibold">
            Cari Tahu Shade yang Paling Sesuai dengan Kepribadianmu
          </Text>
        </Box>
        <Box>
          <Center boxSize={{ base: "280px", md: "360px", lg: "400px", xl: "480px" }} m="0 auto">
            <Image src={QuizStart} alt="beauty-quiz-start" loading="lazy" objectFit={"cover"} />
          </Center>
          <Button
            px={16}
            py={{ base: 4, md: 8 }}
            mb={{ base: 8, lg: 0 }}
            size="lg"
            onClick={handleStart}
            position="static"
            bg={{ base: "black", lg: "transparent" }}
            color={{ base: "white", lg: "black" }}
            border="1px solid black"
            _focus={{ boxShadow: "none" }}
            _hover={{
              bg: "black",
              color: "white"
            }}
            colorScheme="blackAlpha">
            Mulai Test
          </Button>
        </Box>
      </Box>
    );
  };

  const QuestionComponent = ({ img, choice, start, answer }) => {
    return (
      <>
        <GridItem
          onClick={() => handleClick(answer)}
          textAlign="center"
          colStart={start}
          colSpan={2}>
          <Image m="0 auto" src={img} alt="quiz-choices" loading="lazy" />
          <Text fontSize={{ base: "14px", md: "18px" }}>{choice}</Text>
        </GridItem>
      </>
    );
  };

  return (
    <Background>
      <Container
        pt="80px"
        pb={{ base: "120px", md: "64px" }}
        maxW={{ base: "98vw", md: "92vw", lg: "72vw", xl: "60vw" }}>
        <SimpleGrid columns={1}>
          <Progress w={{ md: "101%", lg: "100%" }} colorScheme="gray" value={progress} />
          <Box
            minH={{ base: "420px", md: "560px", lg: "640px", xl: "720px" }}
            minW={{ base: "280px", md: "680px", lg: "700px", xl: "800px" }}
            bgColor={"white"}>
            {!started ? (
              <>
                {!finished ? (
                  <StartComponent />
                ) : (
                  <>
                    {quizResult && statusResult === "success" ? (
                      <Box px={{ base: 4, lg: 8, xl: 20 }} pb={10}>
                        <Box mt={{ base: "16px", md: "32px", lg: "56px" }}>
                          <Text
                            textAlign="left"
                            fontSize={{ base: "32px", md: "40px" }}
                            fontWeight="semibold">
                            {quizResult?.quizTitle}
                          </Text>
                        </Box>
                        <Box mt={"32px"}>
                          <SimpleGrid columns={{ base: 1, md: 2 }} gap={1}>
                            <Box>
                              <Image src={quizResult?.imgModel} loading="lazy" alt="model-result" />
                            </Box>
                            <Box mt={{ base: 5, md: 0 }}>
                              <ResultArticle
                                dangerouslySetInnerHTML={createMarkup(quizResult.quizDesc)}
                              />
                              <Grid
                                mt={10}
                                pt={5}
                                pb={2}
                                px={4}
                                bgColor={"#0f0f0f"}
                                templateColumns="repeat(3, 1fr)"
                                gap={2}>
                                <GridItem colSpan={{ base: 2 }}>
                                  <Text color={"white"} fontWeight={"semibold"} fontSize={"20px"}>
                                    Oh My Glam {quizResult.parentName}
                                    <br />
                                    {quizResult.no + " " + quizResult.name}
                                  </Text>
                                  <Box mt={8}>
                                    <Button
                                      mr={{ base: 2, md: 4 }}
                                      minWidth="80px"
                                      colorScheme="gray"
                                      onClick={() => handleOpen(quizResult)}
                                      _focus={{ boxShadow: "none" }}
                                      _hover={{
                                        bg: "black",
                                        color: "white",
                                        border: "1px solid white"
                                      }}>
                                      Beli
                                    </Button>
                                    <Button
                                      border={"1px solid white"}
                                      color={"white"}
                                      colorScheme="blackAlpha"
                                      _focus={{ boxShadow: "none" }}
                                      _hover={{
                                        bg: "white",
                                        color: "black"
                                      }}
                                      onClick={handleTryAgain}>
                                      Coba Lagi
                                    </Button>
                                  </Box>
                                </GridItem>
                                <GridItem colSpan={{ base: 1 }}>
                                  <Image
                                    objectFit="cover"
                                    src={quizResult?.imgSwatch}
                                    alt="product-result"
                                    minH={{ base: "200px", lg: "220px" }}
                                    position="relative"
                                    top={{ base: "-72px", lg: "-80px" }}
                                    loading="lazy"
                                  />
                                </GridItem>
                              </Grid>
                            </Box>
                          </SimpleGrid>
                        </Box>
                      </Box>
                    ) : (
                      <Center mt={"25%"}>
                        <Spinner />
                        <p>Loading Result...</p>
                      </Center>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {quizDisplay && statusQuiz === "success" ? (
                  <Box px={{ base: 10, md: 20 }} pb={10} textAlign={"center"}>
                    <Box mt={"56px"}>
                      <Text fontSize={{ base: "20px", md: "28px" }} fontWeight="semibold">
                        {quizDisplay.question}
                      </Text>
                    </Box>
                    <Box mt={"32px"}>
                      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
                        <QuestionComponent
                          img={quizDisplay.imgOne}
                          choice={quizDisplay.choice1}
                          start="auto"
                          answer={quizDisplay.answer1}
                        />
                        <QuestionComponent
                          img={quizDisplay.imgTwo}
                          choice={quizDisplay.choice2}
                          start="auto"
                          answer={quizDisplay.answer2}
                        />
                        <QuestionComponent
                          img={quizDisplay.imgThree}
                          choice={quizDisplay.choice3}
                          start="auto"
                          answer={quizDisplay.answer3}
                        />
                        <QuestionComponent
                          img={quizDisplay.imgFour}
                          choice={quizDisplay.choice4}
                          start={2}
                          answer={quizDisplay.answer4}
                        />
                        <QuestionComponent
                          img={quizDisplay.imgFive}
                          choice={quizDisplay.choice5}
                          start="auto"
                          answer={quizDisplay.answer5}
                        />
                      </Grid>
                    </Box>
                  </Box>
                ) : (
                  <Center mt={"25%"}>
                    <Spinner />
                  </Center>
                )}
              </>
            )}
          </Box>
        </SimpleGrid>
      </Container>
      <Modal
        isOpen={modalShow}
        size={isMobile ? "sm" : "2xl"}
        onClose={() => handleClose()}
        isCentered={true}>
        <ProductModal PopUpData={popupData} />
      </Modal>
    </Background>
  );
};

export default QuizComponent;
